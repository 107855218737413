import React from "react";
import Header from "../Header/Header";
import heart from "../../assets/heart.png";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import calories from "../../assets/calories.png";
import "./Hero.css";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";
const Hero = () => {
  const transition = { duration: 3, type: "spring" };
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero">
      <div className="blur hero-blur"> </div>{" "}
      <div className="hero-left">
        <Header /> {/* the best ad  */}{" "}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "178px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>{" "}
          <span> the best fitness club in the town </span>{" "}
        </div>{" "}
        {/* hero text  */}{" "}
        <div class="hero-text">
          <div>
            <span class="stroke-text"> Shape </span> <span> Your </span>{" "}
          </div>{" "}
          <div>
            <span> Ideal body </span>{" "}
          </div>{" "}
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest{" "}
            </span>{" "}
          </div>{" "}
        </div>{" "}
        {/* figure  */}{" "}
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={140} start={100} delay={4} preFix="+" />
            </span>{" "}
            <span> expert coaches </span>{" "}
          </div>{" "}
          <div>
            <span>
              <NumberCounter end={978} start={878} delay={4} preFix="+" />
            </span>{" "}
            <span> Members joined </span>{" "}
          </div>{" "}
          <div>
            <span>
              <NumberCounter end={50} delay={2} preFix="+" />
            </span>{" "}
            <span> fitness programs </span>{" "}
          </div>{" "}
        </div>{" "}
        {/* button  */}{" "}
        <div class="hero-buttons">
          <button class="btn"> Get Started </button>{" "}
          <button class="btn"> Learn More </button>{" "}
        </div>{" "}
      </div>{" "}
      <div className="hero-right">
        <button class="btn">
          <a href="https://www.google.fr/"> Join Now </a>{" "}
        </button>{" "}
        <motion.div
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
          class="heart-rate"
        >
          <img src={heart} alt="" />
          <span> Heart Rate </span> <span> 116 bpm </span>{" "}
        </motion.div>{" "}
        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={hero_image_back}
          alt=""
          className="hero-image-back"
        />
        <motion.div
          initial={{ right: "32rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
          className="calories"
        >
          <img src={calories} alt="" />
          <div>
            <span> Calories burned </span> <span> 220 kcal </span>{" "}
          </div>{" "}
        </motion.div>{" "}
      </div>{" "}
    </div>
  );
};

export default Hero;
